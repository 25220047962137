import ReactDOM from 'react-dom';
import React from 'react';
import {IntlProvider} from 'react-intl';
import PageWrapper from '../../components/PageWrapper';
import Login from './Login';

const {messages, ...props} = window.__APP_INITIAL_STATE__;

ReactDOM.render(
  <IntlProvider locale={props.locale} messages={messages}>
    <PageWrapper {...props} pageWrapperClass='s-ckm-login'>
      <Login {...props} />
    </PageWrapper>
  </IntlProvider>,
  document.getElementById('app')
);

import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import ShibbolethIDPLink from '../../components/common/ShibbolethIDPLink';
import InternalLink from '../../components/common/InternalLink';
import {OPENATHENS_ENTITYID} from '../../constants';

const InstitutionLoginLink = (props) => {
  const { otherInstitutionLoginHref, setDataAnalyticsLogin } = props;

  return (
    <section>
      <h3>
        <FormattedMessage
          id='LoginPage.heading.institutionLoginv2'
          defaultMessage='More Options'
        />
      </h3>
      <div className='u-els-margin-top'>
        <p className='c-ckm-form-sub-text u-ckm-margin--reset'>
          <InternalLink href='/register' onClick={setDataAnalyticsLogin}>
            <FormattedMessage
              id='LoginPage.link.redeemRegID'
              defaultMessage='Redeem a Registration ID'
            />
          </InternalLink>
        </p>
        <p className='c-ckm-form-sub-text u-ckm-margin--reset'>
          <ShibbolethIDPLink
            entityID={OPENATHENS_ENTITYID}
            studyToolsLoginType={props.studyToolsLoginType}
            onClick={setDataAnalyticsLogin}
          >
            <FormattedMessage
              id='LoginPage.link.openAthensLogin'
              defaultMessage='Log in via OpenAthens'
            />
          </ShibbolethIDPLink>
        </p>
        <p className='c-ckm-form-sub-text u-ckm-margin--reset'>
          <InternalLink href={otherInstitutionLoginHref} onClick={setDataAnalyticsLogin}>
            <FormattedMessage
              id='LoginPage.link.otherInst'
              defaultMessage='Log in via your institution'
            />
          </InternalLink>
        </p>
      </div>
    </section>
  );
};

InstitutionLoginLink.propTypes = {
  otherInstitutionLoginHref: PropTypes.string
};

InstitutionLoginLink.displayName = 'InstitutionLoginLink';

export default InstitutionLoginLink;

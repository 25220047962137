import React from 'react';
import {FormattedMessage} from 'react-intl';

export default (props) => (
  <div className='c-ckm-banner c-ckm-banner--success u-els-font-size-meta o-els-flex-layout'>
    <span className='o-els-flex-layout__item' aria-hidden>
      <svg className='o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--middle u-els-margin-right-1o2'>
        <use xlinkHref='#icon-sprite_els-hmds-icon-checkmark' />
      </svg>
    </span>
    <span className='o-els-flex-layout__item o-els-flex-layout__item--grow'>
      <FormattedMessage
        id='LoginPage.text.currentInstitutionv2'
        defaultMessage='Welcome! We recognize your network - {institutionName}'
        values={{
          institutionName: props.accountName
        }}
      />
    </span>
  </div>
);
